import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../infrastructure/ioc/container-types";
import ServiceInterface from "../service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import "tailwindcss/tailwind.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfProduct from "@/vue/domain/product/df-product";
import DfCategory from "@/vue/domain/category/df-category";
import DfPaging from "@/vue/domain/paging/df-paging";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfGroup from "@/vue/domain/group/df-group";
import DfContent from "@/vue/domain/content/df-content";
import Firebase from "@/vue/infrastructure/utils/firebase";
import Swal from "sweetalert2";
import DfCoordinates from "@/vue/domain/store/df-coordinates";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DfUser from "@/vue/domain/user/df-user";
import DfFirebaseTopicType from "@/vue/domain/firebase/df-firebase-topic-type";
const DfSetComponent = () => import(/* webpackChunkName: 'df-card-product' */ "@/vue/components/df-set/df-set.vue");
const DfModalComponent = () => import(/* webpackChunkName: 'df-modal' */ "@/vue/components/df-modal/df-modal.vue");
const lazyLoadingEnabled = process.env.VUE_APP_LAZY_LOADING_ENABLED;

@Component({
  components: {
    DfHeader: () => Utils.externalComponent2("df-header"),
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfAppNavigation: () => Utils.externalComponent2("df-app-navigation"),
    DfModal: DfModalComponent,
    DfSet: DfSetComponent,
    DfSectionProductsFilter: () => Utils.externalComponent2("df-section-products-filter"),
    PulseLoader,
    DfProductDetailsData: () => Utils.externalComponent2("df-product-datails-data"),
    DfProductDetailsRelatedProducts: () => Utils.externalComponent2("df-product-datails-related-products"),
    DfProductDetailsExtraContent: () => Utils.externalComponent2("df-product-datails-extra-content"),
    DfProductDetailsImages: () => Utils.externalComponent2("df-product-details-images"),
  },
})
export default class ApplicationComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private storeLoaded = false;
  private storesLoaded = false;
  private brandLoaded = false;
  private userLoaded = false;
  private busy = false;
  private brandContents: Array<string> = ["BAN", "BTN", "NWS", "PSH", "CMP", "RCP", "PAG", "FML"];
  private storeContents: Array<string> = ["BTN", "NWS", "PSH", "CMP", "RCP", "FLY"];
  private promotionContentsCodes: Array<string> = ["BAN", "FEA", "SPC", "TAG", "CAT", "FLY", "RCP", "POP"];
  private geolocation: DfCoordinates = null;
  private lazyLoadingEnabled = true;
  private modalProduct: DfProduct = null;
  private refreshTokenAttemptsRemaining = 1;
  private oneSignalInitialized = false;

  get readyToStart(): boolean {
    const rule01: boolean = this.storeLoaded && this.brandLoaded;
    const rule02: boolean = this.storesLoaded || !!this.currentStore;
    const rule03: boolean = this.userLoaded || !Utils.enablePrivateArea();

    return rule01 && rule02 && rule03;
  }

  get loadingBackgroundStyle(): string {
    return `background-color: ${Utils.getLoadingBackgroundColor()}`;
  }

  get developmentMode(): boolean {
    return Utils.getProfileMode() === Utils.PROFILE_MODE_DEVELOPMENT;
  }

  get developmentModeLogo(): string {
    return `${Utils.getPublicPath()}/assets/develop-mode-logo.gif`;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  set currentStore(currentStore: DfStore) {
    this.$store.dispatch("setCurrentStore", currentStore);
  }

  get stores(): Array<DfStore> {
    return this.$store.getters.stores;
  }

  set stores(stores: Array<DfStore>) {
    this.$store.dispatch("setStores", stores);
  }

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  set currentPromotion(currentPromotion: DfPromotion) {
    this.$store.dispatch("setCurrentPromotion", currentPromotion);
  }

  get promotions(): Array<DfPromotion> {
    return this.$store.getters("promotions");
  }

  set promotions(promotions: Array<DfPromotion>) {
    this.$store.dispatch("setPromotions", promotions);
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get storedPushesList(): Array<any> {
    return this.$store.getters.storedPushesList;
  }

  set storedPushesList(storedPushesList: Array<any>) {
    this.$store.dispatch("setStoredPushesList", storedPushesList);
  }

  get showAppNavigation(): boolean {
    return this.$route.name !== "store-selection" && this.$route.name !== "fullscreen-pdf-viewer-page";
  }

  get showFilterMenu(): boolean {
    return this.$route.name === "promotion" && !this.hideProductsView;
  }

  get products(): Array<DfProduct> {
    return this.$store.getters.products;
  }

  set products(products: Array<DfProduct>) {
    this.$store.dispatch("setProducts", products);
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  set cachedProducts(products: Array<DfProduct>) {
    this.$store.dispatch("setCachedProducts", products);
  }

  get productsPaging(): DfPaging {
    return this.$store.getters.productsPaging;
  }

  set productsPaging(productsPaging: DfPaging) {
    this.$store.dispatch("setProductsPaging", productsPaging);
  }

  get storesPaging(): DfPaging {
    return this.$store.getters.storesPaging;
  }

  set storesPaging(storesPaging: DfPaging) {
    this.$store.dispatch("setStoresPaging", storesPaging);
  }

  get lastProductsPage(): boolean {
    return this.productsPaging ? this.productsPaging.last : true;
  }

  get promotionContents(): Array<DfContent> {
    return this.$store.getters.promotionContents;
  }

  get tags(): Array<DfCategory> {
    const tagsContent: Array<DfContent> = this.promotionContents.filter((content: DfContent) => content.type.code === "TAG");
    const primaryTag: Array<DfCategory> = tagsContent
      .filter((tagContent: DfContent) => Utils.getPropertyValue(tagContent, Utils.PROPERTY_CONTENT_PRIMARY, "BOOLEAN"))
      .sort((contentA: DfContent, contentB: DfContent) => contentB.priority - contentA.priority)
      .map((tagContent: DfContent) => new DfCategory(tagContent.alias, tagContent.name));
    const secondaryTag: Array<DfCategory> = tagsContent
      .filter((tagContent: DfContent) => !Utils.getPropertyValue(tagContent, Utils.PROPERTY_CONTENT_PRIMARY, "BOOLEAN"))
      .sort((contentA: DfContent, contentB: DfContent) => contentB.priority - contentA.priority)
      .map((tagContent: DfContent) => new DfCategory(tagContent.alias, tagContent.name));

    return [...primaryTag, ...secondaryTag];
  }

  get selectedTags(): Array<DfCategory> {
    return this.$store.getters.activeTags;
  }

  get selectedCategory(): any {
    const category: DfContent = this.$store.getters.activeCategory;
    return category ? { code: category.alias, label: category.name } : null;
  }

  get productsQueryValue(): string {
    return this.$store.getters.productsQueryValue;
  }

  get clientNotification(): boolean {
    return this.$store.getters.clientNotification;
  }

  set clientNotification(clientNotification: boolean) {
    if (clientNotification) {
      this.askNotificationPermission();
    } else {
      this.$store.dispatch("setClientNotification", clientNotification);
      Utils.localStorageSetItem(Utils.LOCAL_STORAGE_CLIENT_NOTIFICATION, clientNotification.toString());
    }
  }

  get clientNotificationSupported(): boolean {
    return "Notification" in window;
  }

  get geolocationEnabled(): boolean {
    return this.$store.getters.geolocationEnabled;
  }

  set geolocationEnabled(geolocationEnabled: boolean) {
    this.$store.dispatch("setGeolocationEnabled", geolocationEnabled);
  }

  get customFiltersQuery(): Array<string> {
    return this.$store.getters.customFiltersQuery;
  }

  get promotionsLoaded(): boolean {
    return this.$store.getters.promotionsLoaded;
  }

  set promotionsLoaded(promotionsLoaded: boolean) {
    this.$store.dispatch("setPromotionsLoaded", promotionsLoaded);
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  set brand(brand: DfBrand) {
    this.$store.dispatch("setBrand", brand);
  }

  get storeDefaultViewMode(): any {
    const valueList: any = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_PRODUCTS_VIEW_MODE, "LIST", { key: Utils.PRODUCTS_ALL_VIEWS });
    return valueList.key;
  }

  get user(): DfUser {
    return this.$store.getters.user;
  }

  set user(user: DfUser) {
    this.$store.dispatch("setUser", user);
  }

  get showHeader(): boolean {
    return this.$route.name !== "fullscreen-pdf-viewer-page";
  }

  // gestione visibilità filtri tag per promo:
  get hideProductsView(): boolean {
    const defaultViewMode: string = this.promotionDefaultViewMode || this.brandDefaultViewMode; // || this.storeDefaultViewMode;
    return defaultViewMode === Utils.PRODUCTS_FLYER_VIEW;
  }

  get promotionDefaultViewMode(): string {
    const valueList: any = Utils.getPropertyValue(this.currentPromotion, Utils.PROPERTY_PRODUCTS_DEFAULT_VIEW_MODE, "LIST");
    return valueList ? valueList.key : null;
  }

  get brandDefaultViewMode(): string {
    const valueList: any = Utils.getPropertyValue(this.brand, Utils.PROPERTY_PRODUCTS_DEFAULT_VIEW_MODE, "LIST");
    return valueList ? valueList.key : null;
  }

  beforeMount() {
    //listen for link click events at the document level
    if (document.addEventListener) {
      document.addEventListener("click", this.interceptClickEvent);
    }

    const shoppingList: string = Utils.localStorageGetItem("df-shopping-list");
    if (shoppingList) {
      this.shoppingList = JSON.parse(shoppingList).map((data: any) => {
        return new DfShoppingProduct(data._description, data._checked);
      });
    }
    //this.registerServiceWorker();
    this.$store.dispatch("setClientNotification", Utils.localStorageGetItem(Utils.LOCAL_STORAGE_CLIENT_NOTIFICATION) === "true");
    this.storedPushesList = Utils.localStorageGetItem("df-pushes-list") ? JSON.parse(Utils.localStorageGetItem("df-pushes-list")) : [];
    this.lazyLoadingEnabled = lazyLoadingEnabled === "true";
    this.checkAppLogin();
  }

  mounted() {
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
  }

  created() {
    this.$root.$on("loadProductsPage", (pagingData: DfPaging) => {
      this.loadProducts(pagingData);
    });
    this.$root.$on("loadStores", (pagingData: DfPaging, searchQuery: string) => {
      this.loadStores(pagingData, searchQuery);
    });
    this.$root.$on("loadCachedProducts", (productsUniqueId: Array<string>, limit: number, callback: any = null) => {
      this.loadCachedProducts(productsUniqueId, limit, callback);
    });
    this.$root.$on("loadCachedProduct", (productsAlias: string, callback: any) => {
      this.loadCachedProduct(productsAlias, callback);
    });
    this.$root.$on("loadNextProductsPage", () => {
      if (this.lazyLoadingEnabled) {
        this.loadNextProductsPage();
      }
    });
    this.$root.$on("loadNextProductsPageManual", () => {
      this.loadNextProductsPage();
    });
    this.$root.$on("login", (callback: any) => {
      this.login(callback);
    });
    this.$root.$on("logout", (callback: any) => {
      this.logout(callback);
    });
    this.$root.$on("loadUser", (callback: any) => {
      this.loadUser(callback);
    });
    this.$root.$on("refreshToken", () => {
      this.refreshToken();
    });

    this.initFirebase();
    document.addEventListener("deviceready", this.oneSignalInit, false);
    document.addEventListener("onFirebaseReady", this.initFirebase, false);
    if ((<any>window).digitalFlyerMessage) {
      this.handleNotificationMessage((<any>window).digitalFlyerMessage);
    }
    document.addEventListener(
      "openAppFromUrl",
      (evt: any) => {
        console.log("SMT: Universal link event data B", evt);
        this.$router.push(evt.data.url);
      },
      false
    );

    window.addEventListener("message", this.smtPdfViewerEventHandler, false);
  }

  @Watch("readyToStart")
  private readyToStartChanged() {
    if (this.readyToStart) {
      if ((<any>navigator).splashscreen) {
        (<any>navigator).splashscreen.hide();
      }
      if (this.$route.name !== "page" && this.$route.name !== "email-change-no-store") {
        if (!this.currentStore && this.$route.name !== "store-selection") {
          this.$store.dispatch("setInitialRoute", this.$route);
          this.$router.push({ name: "store-selection" });
        } else if (this.currentStore && this.$route.path.startsWith("/account/") && !this.user) {
          this.$store.dispatch("setInitialRoute", this.$route);
          this.$router
            .push({ name: "home-store", params: { storeAlias: this.currentStore.alias } })
            .then(() => {
              this.$store.dispatch("setShowLogin", true);
            })
            .catch((err) => err);
        } else if (this.currentStore && (this.$route.name === "store-selection" || !this.$route.name)) {
          this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
        } else if (this.currentStore && (this.$route.meta || {}).redirectTo) {
          this.$router.replace({
            name: this.$route.meta.redirectTo,
            params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias }),
            query: this.$route.query,
          });
        }
      }
    }
  }

  @Watch("currentStore")
  private currentStoreChanged(newStore: DfStore, oldStore: DfStore) {
    const rule01 = !!newStore;
    const rule02: boolean = newStore && oldStore ? newStore.alias !== oldStore.alias : true;

    if (rule01 && rule02) {
      if (oldStore) {
        Firebase.unsubscribe(DfFirebaseTopicType.STORE.getCode(oldStore.identifier));
      }
      Firebase.subscribe(DfFirebaseTopicType.STORE.getCode(newStore.identifier));
      this.promotions = [];

      this.loadStoreContents(this.storeContents);
      this.loadStoreOpenings();
      this.loadStoreClosures();
      this.loadPromotions();

      if (this.user && Utils.getPropertyValue(newStore, Utils.PROPERTY_PRIVATE_AREA_DISABLED, "BOOLEAN", false)) {
        this.$root.$emit("logout", () => {
          this.$root.$emit("login", () => {
            if (this.$route.name !== "home-store") {
              this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
            }
          });
        });
      }
    }
  }

  @Watch("currentPromotion", { immediate: true })
  private currentPromotionChanged(newPromotion: DfPromotion, oldPromotion: DfPromotion) {
    const rule01 = !!newPromotion;
    // const rule02: boolean = newPromotion && oldPromotion ? newPromotion.alias !== oldPromotion.alias : true;
    if (rule01) {
      this.loadPromotionContents(this.promotionContentsCodes);
      this.loadPromotionGroups();
      this.$store.dispatch("setActiveCategory", null);
      this.$store.dispatch("setCachedProducts", []);
    }
  }

  @Watch("shoppingList")
  private shoppingListChanged() {
    this.saveShoppingList();
  }

  @Watch("$route", { immediate: true })
  private routeChanged() {
    if (this.$route.fullPath.startsWith("/password-dimenticata")) {
      this.$root.$emit("modules-modal.open", "df-modal.id.product-details");
    }

    if (this.$route.params.promotionAlias) {
      this.currentPromotion = this.$store.getters.allPromotions.find((promotion: DfPromotion) => promotion.alias === this.$route.params.promotionAlias) || null;
    } else if (this.$route.name === "promotions" && this.$route.query.tags && this.$store.getters.allPromotions.length) {
      this.currentPromotion = this.$store.getters.allPromotions[0];
    } else {
      this.currentPromotion = null;
    }

    const currentStoreAlias: string = this.$route.params.storeAlias || Utils.localStorageGetItem("df-current-store-alias");
    // Nota: può accadere che il current store non sia presente nella lista degli store a causa della paginazione
    const allStores: Array<DfStore> = this.currentStore ? [...this.stores, this.currentStore] : this.stores;
    this.currentStore = currentStoreAlias ? allStores.find((store: DfStore) => store.alias === currentStoreAlias) || null : null;
    Utils.localStorageSetItem("df-current-store-alias", currentStoreAlias || "");

    if (this.currentStore) {
      if ((this.$route.meta || {}).redirectTo) {
        this.$router.replace({
          name: this.$route.meta.redirectTo,
          params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias }),
          query: this.$route.query,
        });
      } else if (this.$route.name === "promotions" && this.currentPromotion) {
        this.$router.replace({
          name: "promotion",
          params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias, promotionAlias: this.currentPromotion.alias }),
          query: this.$route.query,
        });
      } else if (this.$route.path.startsWith("/account/") && !this.user) {
        this.$store.dispatch("setInitialRoute", this.$route);
        this.$router
          .push({ name: "home-store", params: { storeAlias: this.currentStore.alias } })
          .then(() => {
            this.$store.dispatch("setShowLogin", true);
          })
          .catch((err) => err);
      }
    }
  }

  @Watch("geolocationEnabled")
  private geolocationEnabledChange() {
    this.getCurrentPosition();
  }

  @Watch("promotionsLoaded")
  private promotionsLoadedChange() {
    if (this.promotionsLoaded && this.$route.name === "promotions" && this.$route.query.tags) {
      this.currentPromotion = this.$store.getters.allPromotions[0];
      this.$router.replace({
        name: "promotion",
        params: Object.assign({}, this.$route.params, { storeAlias: this.currentStore.alias, promotionAlias: this.currentPromotion.alias }),
        query: this.$route.query,
      });
    }
  }

  @Watch("brand")
  private brandChange() {
    //Firebase.subscribe("WWNDQYSSCZNME");
    Firebase.subscribe(DfFirebaseTopicType.BRAND.getCode(this.brand.identifier));
  }

  private login(callback: any) {
    const tokenKey: string = Utils.localStorageGetItem(Utils.BEARER_ACCESS_TOKEN_KEY);

    if (!tokenKey) {
      this.service
        .login()
        .then(() => {
          callback ? callback() : null;
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    } else {
      callback ? callback() : null;
    }
  }

  private refreshToken() {
    this.service
      .refreshToken()
      .then(() => {
        this.checkAppLogin();
      })
      .catch((error: Error) => {
        this.login(this.checkAppLogin);
      });
  }

  private logout(callback: any) {
    this.service
      .logout()
      .then(() => {
        this.$store.dispatch("setUser", null);
        Utils.localStorageRemoveItem(Utils.BEARER_ACCESS_TOKEN_KEY);
        Utils.localStorageRemoveItem(Utils.BEARER_REFRESH_TOKEN_KEY);
        callback ? callback() : null;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private initializeApp() {
    this.geolocationEnabled = Utils.localStorageGetItem(Utils.LOCAL_STORAGE_GEO_LOCALIZATION) === "true";
    this.loadBrand();
  }

  private checkAppLogin() {
    this.loadUser(this.initializeApp);
  }

  private loadBrand() {
    this.service
      .getBrand()
      .then((brandData: DfBrand) => {
        this.brand = brandData;
        this.brandLoaded = true;

        const brandName: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_NAME, "STRING", this.brand.description);
        document.title = `${document.title} | ${brandName}`;

        this.loadStore(Utils.localStorageGetItem("df-current-store-alias"));
        this.loadBrandContents(this.brandContents);
      })
      .catch((error: Error) => {
        Utils.localStorageRemoveItem(Utils.BEARER_ACCESS_TOKEN_KEY);
        if (Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY)) {
          this.refreshToken();
        } else {
          this.login(this.checkAppLogin);
        }
      });
  }

  private loadUser(callback: any = null) {
    if (Utils.enablePrivateArea()) {
      this.service
        .getUser()
        .then((user: DfUser) => {
          if (user) {
            this.user = user;
            if (Utils.enableLoyalGuruCoupon() && Utils.isMobile()) {
              const loyaGuruTokenPush: string = Utils.getPropertyValue(user, Utils.PROPERTY_LOYALGURU_TOKEN_PUSH, "STRING");
              if (process.env.VUE_APP_ONESIGNAL_APP_ID && loyaGuruTokenPush !== process.env.VUE_APP_ONESIGNAL_APP_ID) {
                this.service.setLoyalGuruData({ oneSignalId: process.env.VUE_APP_ONESIGNAL_APP_ID, app: true });
              }
            }
          }
          this.userLoaded = true;
          callback ? callback() : null;
        })
        .catch(() => {
          this.$store.dispatch("setUser", null);
          Utils.localStorageRemoveItem(Utils.BEARER_ACCESS_TOKEN_KEY);
          if (Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY) && this.refreshTokenAttemptsRemaining > 0) {
            this.refreshTokenAttemptsRemaining--;
            this.refreshToken();
          } else {
            Utils.localStorageRemoveItem(Utils.BEARER_REFRESH_TOKEN_KEY);
            this.login(this.checkAppLogin);
          }
        });
    } else {
      callback ? callback() : null;
    }
  }

  private loadBrandContents(typeCode: Array<string>) {
    this.service
      .getBrandContents({ typeCode: typeCode })
      .then((response: any) => {
        this.$store.dispatch("setBrandContents", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStore(storeAlias: string) {
    if (storeAlias) {
      this.service
        .getStore({ storeAlias: storeAlias })
        .then((store: DfStore) => {
          this.currentStore = store;
          this.storeLoaded = true;
        })
        .catch((error: Error) => {
          this.currentStore = null;
          this.storeLoaded = true;
          this.$router.push({ name: "store-selection" });
        });
    } else if (this.stores.length === 0) {
      this.loadStores();
      this.storeLoaded = true;
    } else {
      this.storeLoaded = true;
    }
  }

  private loadStores(pagingData: DfPaging = null, searchQuery: string = null) {
    pagingData = pagingData || new DfPaging(0, 0, 0, 0, false, true, false, 0);
    Utils.setWorking(true);
    this.busy = true;
    this.service
      .getStores({ geolocation: this.geolocationEnabled ? this.geolocation : null, paging: pagingData, searchQuery: searchQuery })
      .then((response: any) => {
        this.stores = pagingData.number > 0 ? this.stores : [];
        this.stores = [...this.stores, ...response.stores];
        if (this.stores.length === 1 && !searchQuery) {
          this.currentStore = this.stores[0];
        }
        this.storesPaging = response.paging;
        Utils.setWorking(false);
        this.busy = false;
        this.storesLoaded = true;
      })
      .catch((error: Error) => {
        Utils.setWorking(false);
        this.busy = false;
        // this.storesLoaded = true;
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStoreContents(typeCode: Array<string>) {
    this.service
      .getStoreContents({
        storeAlias: this.currentStore.alias,
        typeCode: typeCode,
      })
      .then((response: any) => {
        this.$store.dispatch("setStoreContents", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStoreOpenings() {
    this.service
      .getStoreOpenings({
        storeAlias: this.currentStore.alias,
        daysInterval: 30,
      })
      .then((response: any) => {
        this.$store.dispatch("setStoreOpenings", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadStoreClosures() {
    this.service
      .getStoreClosures({
        storeAlias: this.currentStore.alias,
        daysInterval: 30,
      })
      .then((response: any) => {
        this.$store.dispatch("setStoreClosures", response);
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadPromotions() {
    this.promotionsLoaded = false;
    this.service
      .getPromotions({ storeAlias: this.currentStore.alias })
      .then((promotionsData: Array<DfPromotion>) => {
        this.promotions = promotionsData;
        this.currentPromotion = this.$route.params.promotionAlias ? promotionsData.find((promotion: DfPromotion) => promotion.alias === this.$route.params.promotionAlias) || undefined : null;
        this.promotionsLoaded = true;
      })
      .catch((error: Error) => {
        this.currentPromotion = null;
        this.promotions = [];
        Utils.defaultApiErrorHandler(error, this);
      });
  }

  private loadProducts(pagingData: DfPaging) {
    this.products = pagingData && pagingData.number > 0 ? this.products : [];

    if (this.currentPromotion) {
      let contentAliases: Array<string> = this.selectedTags.map((category: DfCategory) => `contentAliases=${category.identifier}`);
      contentAliases = this.selectedCategory ? [...contentAliases, `contentAliases=${this.selectedCategory.code}`] : contentAliases;

      const query: string = this.productsQueryValue ? `query=${this.productsQueryValue}` : null;

      Utils.setWorking(true);
      this.busy = true;
      this.service
        .getProducts({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          paging: pagingData,
          query: query,
          contentAliases: contentAliases,
          customFilters: this.customFiltersQuery,
        })
        .then((response: any) => {
          this.products = pagingData && pagingData.number > 0 ? this.products : [];
          this.products = [...this.products, ...response.products];
          this.productsPaging = response.paging;
          Utils.setWorking(false);
          this.busy = false;
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
          this.busy = false;
        });
    }
  }

  private loadCachedProducts(productsUniqueId: Array<string>, limit: number, callback: any = null) {
    if (productsUniqueId.length > 0 && this.currentPromotion) {
      Utils.setWorking(true);
      this.service
        .getProductsList({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          productsUniqueId: productsUniqueId,
          limit: limit,
        })
        .then((products: Array<DfProduct>) => {
          const filteredProducts: Array<DfProduct> = products.filter((product: DfProduct) => !this.cachedProducts.find((cachedProduct: DfProduct) => cachedProduct.uniqueId === product.uniqueId));
          this.cachedProducts = [...this.cachedProducts, ...filteredProducts];
          Utils.setWorking(false);
          if (callback) {
            callback();
          }
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadCachedProduct(productAlias: string, callback: any = null) {
    if (productAlias && this.currentPromotion) {
      this.service
        .getProduct({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          productAlias: productAlias,
        })
        .then((product: DfProduct) => {
          this.cachedProducts = product && !this.cachedProducts.find((cachedProduct: DfProduct) => cachedProduct.uniqueId === product.uniqueId) ? [...this.cachedProducts, product] : this.cachedProducts;
          Utils.setWorking(false);
          if (callback) {
            callback();
          }
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadProductByCode(productCode: string, callback: any = null) {
    if (productCode && this.currentPromotion) {
      this.service
        .getProductByCode({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          productCode: productCode,
        })
        .then((product: DfProduct) => {
          this.cachedProducts = product && !this.cachedProducts.find((cachedProduct: DfProduct) => cachedProduct.uniqueId === product.uniqueId) ? [...this.cachedProducts, product] : this.cachedProducts;
          Utils.setWorking(false);
          if (callback) {
            callback(product);
          }
        })
        .catch((error: Error) => {
          Utils.setWorking(false);
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadPromotionContents(typeCode: Array<string>) {
    if (this.currentPromotion) {
      this.service
        .getPromotionContents({
          promotionAlias: this.currentPromotion.alias,
          storeAlias: this.currentStore.alias,
          typeCode: typeCode,
        })
        .then((response: any) => {
          this.$store.dispatch("setPromotionContents", response);
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private loadPromotionGroups() {
    if (this.currentPromotion) {
      this.service
        .getPromotionGroups({
          promotionAlias: this.currentPromotion.alias,
        })
        .then((groups: Array<DfGroup>) => {
          this.$store.dispatch("setPromotionGroups", groups);
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private saveShoppingList() {
    if (this.shoppingList.length > 0) {
      Utils.localStorageSetItem("df-shopping-list", JSON.stringify(this.shoppingList));
    } else {
      Utils.localStorageRemoveItem("df-shopping-list");
    }
  }

  private loadNextProductsPage() {
    if (!this.lastProductsPage && !this.busy) {
      this.productsPaging.number++;
      this.$root.$emit("loadProductsPage", this.productsPaging);
    }
  }

  private initFirebase() {
    Firebase.onMessageReceived(this.handleNotificationMessage, this.handleNotificationMessage);
    // Wait to app return in foreground
    document.addEventListener("resume", this.updateStores, false);

    if ((<any>window).cordova && (<any>window).cordova.platformId === "ios") {
      console.log("SMT: Platform iOs grantPermission request");
      Firebase.grantPermission();
    } else {
      console.log("SMT: GrantPermission not required");
    }
    if ((<any>window).cordova && (<any>window).cordova.getAppVersion) {
      (<any>window).cordova.getAppVersion.getVersionNumber((version: any) => {
        const oldAppVersion: string = Utils.localStorageGetItem(Utils.APP_VERSION);
        console.log(`SMT: ${version} vs ${oldAppVersion}`);
        if (version !== oldAppVersion) {
          (<any>window).CacheClear(
            (status: any) => {
              console.log("SMT: Cache Clear Message: " + status);
              Utils.localStorageSetItem(Utils.APP_VERSION, version);
            },
            (error: any) => {
              console.log("SMT: Cache Clear Error: " + error);
            }
          );
        }
      });
    }
    this.oneSignalInit();
  }

  private updateStores() {
    this.loadStore(Utils.localStorageGetItem("df-current-store-alias"));
    this.loadStores();
  }

  private handleNotificationMessage(message: any) {
    let title = "Avviso";
    if (message.title) {
      title = message.title;
    } else if (message.notification && message.notification.title) {
      // oneSignal format e not only from click by system notification
      title = message.notification.title;
    } else if (message.aps && message.aps.alert && message.aps.alert.title) {
      title = message.aps.alert.title;
    }

    let body: string = JSON.stringify(message);
    if (message.alert) {
      // oneSignal format
      body = message.alert;
    } else if (message.body) {
      body = message.body;
    } else if (message.notification && message.notification.body) {
      // oneSignal format e not only from click by system notification
      body = message.notification.body;
    } else if (message.aps && message.aps.alert && message.aps.alert.body) {
      body = message.aps.alert.body;
    }

    // Approfondire la questione sulla documentazione di firebase: il .data potrebbe non arrivare mai
    if (message.data?.linkUrl && message.data?.linkLabel) {
      const button = `<div class="df-mt-4"><a class="df-login-button df-flex df-items-center df-justify-center" target="_blank" title="${message.data.linkLabel}" href="${message.data.linkUrl}">${message.data.linkLabel}</a></div>`;
      body = `${body}${button}`;
    } else if (message.linkUrl && message.linkLabel) {
      const button = `<div class="df-mt-4"><a class="df-login-button df-flex df-items-center df-justify-center" target="_blank" title="${message.linkLabel}" href="${message.linkUrl}">${message.linkLabel}</a></div>`;
      body = `${body}${button}`;
    }

    if (title || body) {
      Swal.fire({
        title: title,
        html: body,
        icon: "info",
        buttonsStyling: false,
      });
    } else if (this.$route.name !== "notifications") {
      this.$router.push({ name: "notifications", params: { storeAlias: this.currentStore.alias } });
    }

    this.loadBrandContents(this.brandContents);
    this.loadStoreContents(this.storeContents);
  }

  private registerServiceWorker() {
    if ("serviceWorker" in navigator && Utils.getAppType() === "web") {
      // Register a service worker hosted at the root of the
      // site using the default scope.
      navigator.serviceWorker.register(`${Utils.getBasePath(true)}/sw.js`).then(
        (registration) => {
          console.log("SMT: Service worker registration succeeded:", registration);
        },
        (error) => {
          console.log("SMT: Service worker registration failed:", error);
        }
      );
    } else {
      console.log("SMT: Service workers are not supported.");
    }
  }

  private askNotificationPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        this.$store.dispatch("setClientNotification", true);
        Utils.localStorageSetItem(Utils.LOCAL_STORAGE_CLIENT_NOTIFICATION, "true");
      }
    });
  }

  private getCurrentPosition() {
    if ("geolocation" in navigator && this.geolocationEnabled) {
      const options: any = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };
      navigator.geolocation.getCurrentPosition(this.geolocationSuccess, this.geolocationError, options);
    } else {
      this.loadStores();
    }
  }

  private geolocationSuccess(pos: any) {
    const crd = pos.coords;
    this.geolocation = new DfCoordinates(crd.latitude, crd.longitude);
    this.loadStores();
    console.log("SMT: Your current position is:");
    console.log(`SMT: Latitude : ${crd.latitude}`);
    console.log(`SMT: Longitude: ${crd.longitude}`);
    console.log(`SMT: More or less ${crd.accuracy} meters.`);
  }

  private geolocationError(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    this.loadStores();
  }

  /*
  NON UTILIZZABILE revoke è infase di  sperimentazione
  private geolocationRevokePermission() {
    navigator.permissions.revoke({name:'geolocation'}).then(() => {
      console.log("Geolocation permission reset");
    });
  }
  */

  private interceptClickEvent(event: any) {
    const target = event.target || {};
    if (target.tagName === "A") {
      let href: string = target.getAttribute("href") || "";
      const hrefSplitted: Array<string> = href.split("/");

      if (hrefSplitted.length === 3 && hrefSplitted[0] === "prodotti") {
        // Gestione link prodotti presenti nel flyer
        //tell the browser not to respond to the link click
        event.preventDefault();
        this.service
          .getProductByLink({ promotionCode: hrefSplitted[1], productCode: hrefSplitted[2], storeAlias: this.currentStore.alias })
          .then((product: DfProduct) => {
            const promotion: DfPromotion = this.promotions.find((promotion: DfPromotion) => promotion.code === hrefSplitted[1]);
            if (promotion) {
              this.$router.push({ name: "product", params: { promotionAlias: promotion.alias, productAlias: product.alias } });
            }
          })
          .catch((error: Error) => {
            Utils.defaultApiErrorHandler(error, this);
          });
      } else if (!href.startsWith("http") && !href.startsWith("blob") && !href.startsWith("mailto") && !href.startsWith("tel") && !href.endsWith("#")) {
        // Gestione link interni (generici)
        // tell the browser not to respond to the link click
        event.preventDefault();
        href = href.replaceAll("../", "");
        href = href.startsWith("/") ? href : `/${href}`;
        href = href.replace(Utils.getBasePath(), "");
        href = href.startsWith("/") ? href : `/${href}`;

        if (href !== this.$route.path) {
          this.$router.push(href);
        }
      }
    }
  }

  private smtPdfViewerEventHandler(event: any) {
    if (event.data.origin === "smt-digitalflyer-viewer") {
      console.log(event);
      switch (event.data.eventType) {
        case "close":
          //this.$root.$emit("modules-modal.close", "df-modal.id.pdf-fullscreen");
          this.$router.push({ name: "promotion", params: { storeAlias: event.data.params.storeAlias, promotionAlias: event.data.params.promotionAlias } });
          Utils.killIubenda(false);
          Utils.setAnaliticsTraker({
            event: "close",
            origin: event.data.origin,
            promotionAlias: event.data.params.promotionAlias,
            promotionBeginDate: event.data.params.promotionBeginDate,
            promotionCode: event.data.params.promotionCode,
            promotionDescription: event.data.params.promotionDescription,
            promotionEndDate: event.data.params.promotionEndDate,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          break;
        case "downloadPdf":
          Utils.setAnaliticsTraker({
            event: "download-pdf",
            origin: event.data.origin,
            promotionAlias: event.data.params.promotionAlias,
            promotionBeginDate: event.data.params.promotionBeginDate,
            promotionCode: event.data.params.promotionCode,
            promotionDescription: event.data.params.promotionDescription,
            promotionEndDate: event.data.params.promotionEndDate,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          break;
        case "openPromotion":
          if (this.currentPromotion?.alias !== event.data.params.promotionAlias) {
            Utils.setAnaliticsTraker({
              event: "open-promotion",
              origin: event.data.origin,
              promotionAlias: event.data.params.promotionAlias,
              promotionBeginDate: event.data.params.promotionBeginDate,
              promotionCode: event.data.params.promotionCode,
              promotionDescription: event.data.params.promotionDescription,
              promotionEndDate: event.data.params.promotionEndDate,
              storeAlias: event.data.params.storeAlias,
              storeCode: event.data.params.storeCode,
              storeName: event.data.params.storeName,
            });
            //this.$router.push({ name: "promotion", params: { storeAlias: event.data.params.storeAlias, promotionAlias: event.data.params.promotionAlias } });
          }
          break;
        case "fullScreenPdf":
          //this.$root.$emit("modules-modal.open", "df-modal.id.pdf-fullscreen");
          this.$router.push({ name: "fullscreen-pdf-viewer-page", params: { storeAlias: event.data.params.storeAlias, promotionAlias: event.data.params.promotionAlias } });

          Utils.killIubenda(true);
          Utils.setAnaliticsTraker({
            event: "fullscreen-pdf",
            origin: event.data.origin,
            promotionAlias: event.data.params.promotionAlias,
            promotionBeginDate: event.data.params.promotionBeginDate,
            promotionCode: event.data.params.promotionCode,
            promotionDescription: event.data.params.promotionDescription,
            promotionEndDate: event.data.params.promotionEndDate,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          break;
        case "changePage":
          Utils.setAnaliticsTraker({
            event: "change_page",
            origin: event.data.origin,
            pageFrom: event.data.params.pageFrom,
            pageTo: event.data.params.pageTo,
            promotionAlias: event.data.params.promotionAlias,
            promotionBeginDate: event.data.params.promotionBeginDate,
            promotionCode: event.data.params.promotionCode,
            promotionDescription: event.data.params.promotionDescription,
            promotionEndDate: event.data.params.promotionEndDate,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          break;
        case "openProductDetail":
          Utils.setAnaliticsTraker({
            event: "open-product-detail",
            origin: event.data.origin,
            productAlias: event.data.params.productAlias,
            productBrand: event.data.params.productBrand,
            productCategory: event.data.params.productCategory,
            productCode: event.data.params.productCodeproductCode,
            productDescription: event.data.params.productDescription,
            productDiscountType: event.data.params.productDiscountType,
            productFeatured: event.data.params.productFeatured,
            productInitialPrice: event.data.params.productInitialPrice,
            productPrice: event.data.params.productPrice,
            productTitle: event.data.params.productTitle,
            promotionAlias: event.data.params.promotionAlias,
            promotionBeginDate: event.data.params.promotionBeginDate,
            promotionCode: event.data.params.promotionCode,
            promotionDescription: event.data.params.promotionDescription,
            promotionEndDate: event.data.params.promotionEndDate,
            source: event.data.params.source,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          this.modalProduct = this.products.find((product: DfProduct) => product.code.value === event.data.params.productCode);
          if (this.modalProduct) {
            this.$root.$emit("modules-modal.open", "df-modal.id.product-details");
          } else {
            this.loadProductByCode(event.data.params.productCode, (product: DfProduct) => {
              this.modalProduct = product;
              if (this.modalProduct) {
                this.$root.$emit("modules-modal.open", "df-modal.id.product-details");
              }
            });
          }
          break;
        case "changeStore":
          Utils.setAnaliticsTraker({
            event: "change_page",
            origin: event.data.origin,
            pageFrom: 0,
            pageTo: 1,
            promotionAlias: this.currentPromotion.alias,
            promotionBeginDate: this.currentPromotion.startDate,
            promotionCode: this.currentPromotion.code,
            promotionDescription: this.currentPromotion.description,
            promotionEndDate: this.currentPromotion.endDate,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          break;
        case "viewStoreInfo":
          Utils.setAnaliticsTraker({
            event: "show_store_info",
            origin: event.data.origin,
            storeAlias: event.data.params.storeAlias,
            storeCode: event.data.params.storeCode,
            storeName: event.data.params.storeName,
          });
          break;
      }
    }
  }

  private closeProductDetailModal() {
    if (this.modalProduct) {
      Utils.setAnaliticsTraker({
        event: "close_product_modal",
        origin: "smt-digitalflyer-ui",
        productAlias: this.modalProduct.alias,
        productBrand: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_PRODUCT_BRAND, "STRING"),
        productCategory: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_PRODUCT_CATEGORY, "STRING"),
        productCode: this.modalProduct.code,
        productDescription: this.modalProduct.description,
        productDiscountType: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING"),
        productFeatured: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_PRODUCT_FEATURED, "STRING"),
        productInitialPrice: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "STRING"),
        productPrice: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "STRING"),
        productTitle: Utils.getPropertyValue(this.modalProduct, Utils.PROPERTY_TITLE, "STRING"),
        promotionAlias: this.currentPromotion.alias,
        promotionBeginDate: this.currentPromotion.startDate,
        promotionCode: this.currentPromotion.code,
        promotionDescription: this.currentPromotion.description,
        promotionEndDate: this.currentPromotion.endDate,
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });
      this.modalProduct = null;
    }
  }

  private oneSignalInit() {
    const cordova = (<any>window).cordova;
    const plugins = (<any>window).plugins;
    if (cordova && cordova.platformId !== "browser" && plugins?.OneSignal && Utils.enableLoyalGuruCoupon() && !this.oneSignalInitialized) {
      // Remove this method to stop OneSignal Debugging
      plugins.OneSignal.Debug.setLogLevel(6);

      // Uncomment to set OneSignal visual logging to VERBOSE
      // window.plugins.OneSignal.Debug.setAlertLevel(6);

      // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
      console.log("SMT: ONESIGNAL_APP_ID", process.env.VUE_APP_ONESIGNAL_APP_ID);
      plugins.OneSignal.initialize(process.env.VUE_APP_ONESIGNAL_APP_ID);

      //Adds an event listener for clicks on notifications
      const listener = (event: any) => {
        //const notificationPayload = JSON.stringify(event);
        this.handleNotificationMessage(event);
        console.log("SMT: OneSignal", event);
      };
      plugins.OneSignal.Notifications.addEventListener("click", listener);

      //Prompts the user for notification permissions.
      //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
      plugins.OneSignal.Notifications.requestPermission(true).then((accepted: any) => {
        console.log("SMT: User accepted OneSignal notifications: " + accepted);
      });
      this.oneSignalInitialized = true;
    }
  }
}
